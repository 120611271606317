.rotating-picture-slice-window {
	margin: 0 auto;
	overflow: hidden;
}

.rotating-picture-slice {
	position: relative;
	margin-left: 0px;

	-webkit-transition-property: margin-left;
	-webkit-transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-in-out;

	transition-property: margin-left;
	transition-duration: 0.5s;
	transition-timing-function: ease-in-out;
}
