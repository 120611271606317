.project.card {
	
}

.project.card .card-image .card-title {
	font-family: 'Bebas Neue', Arial, sans-serif;
	background-color: #000;
	padding: 5px 10px;
}

.project .btn {
	font-size: 12px;
	background-color: #9DD236;
}

.custom-modal {
	display: none;
	width: 80%;
	max-width: 800px;
	height: auto;
	background-color: #FFF;
	position: fixed;
	z-index: 1003;
	margin: 0 auto;
	top: 10%;
	left: 0;
	right: 0;
	padding: 5px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.custom-modal.visible {
	display: block;
}

.custom-modal.hidden {
	display: none;
}

.custom-modal h5 {
	margin-top: 0px;
}

.custom-modal-close-button {
	float: right;
	margin-top: -35px;
}

.custom-modal-close-button:hover {
	cursor:pointer;
}