.pixelart {
	position: absolute;
	margin-top: -150px;
}

.pixelart--main {
	width: 524px;
	right: 10px;
	margin-top: -150px;
	z-index: 1;
}

.pixelart--waterfall {
	right: 444px;
	margin-top: 210px;
	z-index: 2;
}

.pixelart--floating-ball {
	width: 16px;
	height: 16px;
	right: 195px;
	margin-top: -70px;
	background-image: url("ball-animation.png");
	background-size: 200px;
}

.pixelart--floating-ball--top {
	margin-top: -100px;

	-webkit-transition-property: margin-top;
	-webkit-transition-duration: 1s;
	-webkit-transition-timing-function: ease-in-out;

	transition-property: margin-top;
	transition-duration: 1s;
	transition-timing-function: ease-in-out;
}

.pixelart--floating-ball--bottom {
	margin-top: -70px;

	-webkit-transition-property: margin-top;
	-webkit-transition-duration: 1s;
	-webkit-transition-timing-function: ease-in-out;

	transition-property: margin-top;
	transition-duration: 1s;
	transition-timing-function: ease-in-out;
}

@media (max-width: 600px) {
	.pixelart--main {
		width: 295px;
		margin-top: -160px;
		right: 0px;
	}

	.pixelart--waterfall {
		width: 56px;
		right: 194px;
		margin-top: 42px;
	}

	.pixelart--floating-ball {
		background-image: url("ball-animation.png");
		background-size: 208px;
	}

	.pixelart--floating-ball.position-0 {
		background-position: 0px 0px;
	}
	.pixelart--floating-ball.position-1 {
		background-position: -16px 0px;
	}
	.pixelart--floating-ball.position-2 {
		background-position: -32px 0px;
	}
	.pixelart--floating-ball.position-3 {
		background-position: -48px 0px;
	}
	.pixelart--floating-ball.position-4 {
		background-position: -64px 0px;
	}
	.pixelart--floating-ball.position-5 {
		background-position: -80px 0px;
	}
	.pixelart--floating-ball.position-6 {
		background-position: -96px 0px;
	}
	.pixelart--floating-ball.position-7 {
		background-position: -112px 0px;
	}
	.pixelart--floating-ball.position-8 {
		background-position: -128px 0px;
	}
	.pixelart--floating-ball.position-9 {
		background-position: -144px 0px;
	}
	.pixelart--floating-ball.position-10 {
		background-position: -160px 0px;
	}
	.pixelart--floating-ball.position-11 {
		background-position: -176px 0px;
	}
	.pixelart--floating-ball.position-12 {
		background-position: -192px 0px;
	}
}

@media (min-width: 601px) and (max-width:992px) {
	.pixelart--main {
		width: 443px;
		right: 10px;
		margin-top: -200px;
	}

	.pixelart--waterfall {
		width: 85px;
		right: 301px;
		margin-top: 104px;
	}

	.pixelart--floating-ball {
		width: 20px;
		height: 20px;
		right: 310px;
		margin-top: -60px;
		background-size: 260px;
	}

	.pixelart--floating-ball--top {
		margin-top: -100px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball--bottom {
		margin-top: -60px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball.position-0 {
		background-position: 0px 0px;
	}
	.pixelart--floating-ball.position-1 {
		background-position: -20px 0px;
	}
	.pixelart--floating-ball.position-2 {
		background-position: -40px 0px;
	}
	.pixelart--floating-ball.position-3 {
		background-position: -60px 0px;
	}
	.pixelart--floating-ball.position-4 {
		background-position: -80px 0px;
	}
	.pixelart--floating-ball.position-5 {
		background-position: -100px 0px;
	}
	.pixelart--floating-ball.position-6 {
		background-position: -120px 0px;
	}
	.pixelart--floating-ball.position-7 {
		background-position: -140px 0px;
	}
	.pixelart--floating-ball.position-8 {
		background-position: -160px 0px;
	}
	.pixelart--floating-ball.position-9 {
		background-position: -180px 0px;
	}
	.pixelart--floating-ball.position-10 {
		background-position: -200px 0px;
	}
	.pixelart--floating-ball.position-11 {
		background-position: -220px 0px;
	}
	.pixelart--floating-ball.position-12 {
		background-position: -240px 0px;
	}
}

@media (min-width: 993px) and (max-width: 1200px) {
	.pixelart--main {
		right: 10px;
	}

	.pixelart--waterfall {
		right: 353px;
	}

	.pixelart--floating-ball {
		width: 24px;
		height: 24px;
		right: 360px;
		margin-top: 0px;
		background-size: 300px;
	}

	.pixelart--floating-ball--top {
		margin-top: -30px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball--bottom {
		margin-top: 0px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball.position-0 {
		background-position: 0px 0px;
	}
	.pixelart--floating-ball.position-1 {
		background-position: -23px 0px;
	}
	.pixelart--floating-ball.position-2 {
		background-position: -46px 0px;
	}
	.pixelart--floating-ball.position-3 {
		background-position: -69px 0px;
	}
	.pixelart--floating-ball.position-4 {
		background-position: -92px 0px;
	}
	.pixelart--floating-ball.position-5 {
		background-position: -115px 0px;
	}
	.pixelart--floating-ball.position-6 {
		background-position: -138px 0px;
	}
	.pixelart--floating-ball.position-7 {
		background-position: -161px 0px;
	}
	.pixelart--floating-ball.position-8 {
		background-position: -184px 0px;
	}
	.pixelart--floating-ball.position-9 {
		background-position: -207px 0px;
	}
	.pixelart--floating-ball.position-10 {
		background-position: -230px 0px;
	}
	.pixelart--floating-ball.position-11 {
		background-position: -253px 0px;
	}
	.pixelart--floating-ball.position-12 {
		background-position: -276px 0px;
	}
}

@media (min-width: 1200px) {
	.pixelart--main {
		right: 100px;
	}

	.pixelart--waterfall {
		right: 444px;
		margin-top: 210px;
	}

	.pixelart--floating-ball {
		width: 32px;
		height: 32px;
		right: 440px;
		margin-top: 0px;
		background-size: 416px;
	}

	.pixelart--floating-ball--top {
		margin-top: -30px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball--bottom {
		margin-top: 0px;

		-webkit-transition-property: margin-top;
		-webkit-transition-duration: 1s;
		-webkit-transition-timing-function: ease-in-out;

		transition-property: margin-top;
		transition-duration: 1s;
		transition-timing-function: ease-in-out;
	}

	.pixelart--floating-ball.position-0 {
		background-position: 0px 0px;
	}
	.pixelart--floating-ball.position-1 {
		background-position: -32px 0px;
	}
	.pixelart--floating-ball.position-2 {
		background-position: -64px 0px;
	}
	.pixelart--floating-ball.position-3 {
		background-position: -96px 0px;
	}
	.pixelart--floating-ball.position-4 {
		background-position: -128px 0px;
	}
	.pixelart--floating-ball.position-5 {
		background-position: -160px 0px;
	}
	.pixelart--floating-ball.position-6 {
		background-position: -192px 0px;
	}
	.pixelart--floating-ball.position-7 {
		background-position: -224px 0px;
	}
	.pixelart--floating-ball.position-8 {
		background-position: -256px 0px;
	}
	.pixelart--floating-ball.position-9 {
		background-position: -288px 0px;
	}
	.pixelart--floating-ball.position-10 {
		background-position: -320px 0px;
	}
	.pixelart--floating-ball.position-11 {
		background-position: -352px 0px;
	}
	.pixelart--floating-ball.position-12 {
		background-position: -384px 0px;
	}
}