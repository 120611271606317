.container--projects {
	margin-top: 0px;
}

.container--projects-sub-container {
	box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.3);
	background-color: #3A3A3A;
	margin-top: -31px;
	padding-bottom: 40px;
}

.container--projects h4.projects-sub-head--blocky {
	background-color: #9DD236;
	width: 150px;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	display: inline;
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 0;
	font-size: 28px;
	top: -18px;
	position: relative;
}

.container--projects h4.projects-sub-head {
	background-color: #3A3A3A;
	width: 150px;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	display: inline block;
	padding: 10px 20px;
	margin-right: 0;
	font-size: 28px;
}

.container--projects h4.projects-sub-head::before {
	content: "";
	width: 0px;
	height: 0px;
	border-bottom: 37px solid #3A3A3A;
	border-left: 37px solid transparent;
	float: left;
	margin-top: -4px;
}

.container--projects h4.projects-sub-head::after {
	content: "";
	width: 0px;
	height: 0px;
	border-top: 37px solid #3A3A3A;
	border-right: 37px solid transparent;
	float: right;
	margin-top: -4px;
	margin-left: 0;
}