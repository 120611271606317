.work-history-container {
	background-color: #D3D3D3;
	padding: 20px 0 60px 0;
	margin-top: -20px;
	border-top: 20px solid #3A3A3A;
	box-shadow: inset 0px 0px 200px 10px rgba(100, 100, 100, 0.5);
}

.picture-blurb {
	font-size: 14px;
	margin-top: -0.5em;
	font-weight: bold;
	color: #797979;
	display: none;
}

.work-history-container p.flow-text {
	position: relative;
	z-index: 3;
}

.work-history-container .blurb a,
.work-history-container .blurb strong {
	font-weight: bold;
	color: #000;
	padding-left: 3px;
	padding-right: 3px;
}

.work-history-container .blurb a:hover {
	background-color: #000;
	color: #FFF;

	border-radius: 1px;
}

.work-history-logos {}

.work-history-logo img {
	margin-top: 30px;
}

.work-history-logo--blizzard {}

.work-history-logo--ign {}

.work-history-logo--whistle {
	background-color: green;
}