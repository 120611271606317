.cursor {
	display: inline-block;
}

.cursor.visible {
	display: inline-block;
}

.cursor.hidden {
	display: none;
}