body {
	background-color: #000;
}

.app-header {
	background: rgb(217,217,217);
	background: linear-gradient(159deg, rgba(217,217,217,1) 0%, rgba(217,217,218,1) 35%, rgba(187,196,147,1) 100%);
}

.container--headline {
	padding-top: 120px;
}

.resume-link {
	margin-right: 10px;
	position: relative;
	z-index: 30;
}

.resume-link a {
	color: #000;
	font-size: 14px;
	font-weight: bold;
}

.app-header .resume-link a {
	text-shadow:
		3px 3px 0 #D3D3D3,
		-1px -1px 0 #D3D3D3,  
		1px -1px 0 #D3D3D3,
		-1px 1px 0 #D3D3D3,
		1px 1px 0 #D3D3D3;
}

.resume-link a:hover {
	color: #454C3F;
}

.resume-link i.material-icons {
	float: left;
	margin-top: -5px;
}