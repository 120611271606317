.footer {
	color: #D3D3D3;
	padding-bottom: 80px;
}

.footer .logo {
	margin-top: -15px;
}

.footer .phone-number {
	color: #D3D3D3;
}

.footer .link-icons {
	display: block;
}

.footer .link-icons li {
	float: right;
	margin-left: 20px;
	margin-top: 20px;
}

.footer .link-icons a {
	color: #FFF;
	font-size: 2em;
}

.footer .link-icons a:hover {
	color: #9DD236;
}

.footer .resume-link a {
	color: #FFF;
}

.footer .resume-link a:hover {
	color: #C4C4C4;
}

.phone-number {
	float: right;
	clear: right;
}
.footer .phone-number p {
	font-weight: 700;
	font-size: 18px;
	color: #535353;
	margin-top: 0px;
	padding: 0;
}

.footer p {
	clear: left;
	padding-top: 20px;
	font-size: 16px;
	font-weight: 200;
	text-transform: uppercase;
}

.footer .emphasized {
	font-weight: bold;
	font-size: 36px;
	display: block;
}

.footer .emphasized--small {
	font-size: 24px;
}

.footer .emphasized--inline {
	display: inline;
}

.footer .emphasized.emphasized--light {
	color: #FFF;
}

.footer .emphasized.emphasized--dark {
	color: #5F5F5F;
}

.footer .built-by a {
	color: #FFF;
	font-weight: bold;
}

.footer .built-by a:hover {
	color: #9DD236;
}