.rotating-picture {
	border: 10px solid rgba(255, 255, 255, 0.1);
	border-radius: 3px;
	box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
	margin-top: 1em;
	margin-bottom: 1em;
}

.rotating-picture.col {
	padding: 0 !important;
}
