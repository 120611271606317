.fast-company-container {
	background-color: #000;
	padding: 120px 0 160px 0;
	margin-top: -20px;
	margin-bottom: 30px;

	/* temporary */
	color: #FFF;
}

.fast-company-container p {
	margin-top: 0px;
}

.fast-company-container p a {
	color: #FFF;
	font-weight: bold;
	padding: 0px 1px;

	border-radius: 3px;
}

.fast-company-container p a:hover {
	background-color: #FFF;
	color: #000;
}

.fast-company-container a img {
	margin-top: 10px;
	margin-bottom: 20px;
}

.fast-company-container a:hover img {
	opacity: 0.8;
}