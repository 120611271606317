.container--open-source {
	margin-top: 0px;
}

.container--open-source-sub-container {
	background-color: #242424;
	margin-top: -31px;
	padding-bottom: 40px;
}

.container--open-source h4.open-source-sub-head--blocky {
	background-color: #9DD236;
	width: 150px;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	display: inline;
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 0;
	font-size: 28px;
	top: -18px;
	position: relative;
}

.container--open-source h4.open-source-sub-head {
	background-color: #3A3A3A;
	width: 150px;
	color: #FFF;
	text-transform: uppercase;
	text-align: center;
	display: inline block;
	padding: 10px 20px;
	margin-right: 0;
	font-size: 28px;
}
