.logo {
	margin-left: 10px;
}

.logo--block {

}

.logo--inline {

}

.logo-bar {
	float: left;
}

/* tall version */
.logo-bar--tall .logo-bar {
	width: 20px;
	margin-right: 10px;
}

.logo-bar--tall .logo-bar--long {
	height: 150px;
}

.logo-bar--tall .logo-bar--short {
	height: 75px;
}

/* medium version */
.logo-bar--medium .logo-bar {
	width: 15px;
	margin-right: 7px;
}

.logo-bar--medium .logo-bar--long {
	height: 100px;
}

.logo-bar--medium .logo-bar--short {
	height: 56px;
}

/* short version */
.logo-bar--tiny .logo-bar {
	width: 10px;
	margin-right: 5px;
}


.logo-bar--tiny .logo-bar--long {
	height: 75px;
}

.logo-bar--tiny .logo-bar--short {
	height: 37px;
}

/* dark version */
.logo-bar--black .logo-bar--dark {
	background-color: #000;
}

.logo-bar--black .logo-bar--light {
	background-color: #5A5A5A;
}

/* light version */
.logo-bar--white .logo-bar--dark {
	background-color: #FFF;
}

.logo-bar--white .logo-bar--light {
	background-color: #5A5A5A;
}
