.headline {
	font-family: 'Bebas Neue', Arial, sans-serif;
	font-weight: bold;
	margin-left: 10px;
	height: 300px;
	padding-bottom: 100px;
	position: relative;
	z-index: 10;

	/*
	color: rgba(88, 88, 88, 0.87);
	*/

	/*
	-webkit-text-stroke: 1px #FFF;
	*/

	/*
	text-shadow:
		3px 3px 0 #D3D3D3,
		-1px -1px 0 #D3D3D3,  
		1px -1px 0 #D3D3D3,
		-1px 1px 0 #D3D3D3,
		1px 1px 0 #D3D3D3;
	*/
}

/*
.headline .name {
	color: rgba(0, 0, 0, 0.87);
}
*/

@media (max-width: 600px) {
	.headline {
		font-size: 58px;
		line-height: 44px;
		height: 175px;
	}
}

@media (min-width: 601px) and (max-width:992px) {
	.headline {
		font-size: 90px;
		line-height: 68px;
		height: 230px;
	}
}

@media (min-width: 993px) and (max-width: 1105px) {
	.headline {
		font-size: 105px;
		line-height: 80px;
		height: 270px;
	}
}

@media (min-width: 1106px) and (max-width: 1200px) {
	.headline {
		font-size: 120px;
		line-height: 90px;
		height: 300px;
	}
}

@media (min-width: 1200px) and (max-width: 1270px) {
	.headline {
		font-size: 124px;
		line-height: 98px;
	}
}

@media (min-width: 1271px) and (max-width: 1500px) {
	.headline {
		font-size: 132px;
		line-height: 100px;
	}
}

@media (min-width: 1501px) {
	.headline {
		font-size: 154px;
		line-height: 120px;
	}
}